import React, { useState, useEffect } from "react"
import PageLink from "./page-link"
import Dropdown from "./dropdown"

const NavItem = ({ item, isActive }) => {
  const [visible, toggleVisible] = useState(false)

  useEffect(() => {
    if (!!isActive && !!visible === false) {
      toggleVisible(!visible)
    }
  }, [])

  return (
    <li key={item.id}>
      <PageLink
        key={item.id}
        item={item}
        visible={visible}
        childrenCount={
          !!item.frontmatter.sections ? item.frontmatter.sections.length : 0
        }
        toggleVisible={toggleVisible}
        isActive={isActive}
      />
      <Dropdown
        visible={visible}
        item={item}
        sections={!!item.frontmatter.sections ? item.frontmatter.sections : []}
      />
    </li>
  )
}

export default NavItem
