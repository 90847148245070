import React from "react"
import { Link } from "gatsby"
import arrow from "../images/arrow.svg"

const PageLink = ({
  isActive,
  item,
  visible,
  childrenCount,
  toggleVisible,
}) => {
  return (
    <span
      className={
        childrenCount > 0
          ? "dropdown-wrapper --has-dropdown"
          : "dropdown-wrapper"
      }
    >
      <Link
        to={item.frontmatter.path}
        activeClassName="active"
        onClick={() => {
          toggleVisible(!visible)
        }}
        partiallyActive={true}
        className={isActive ? "active" : ""}
      >
        {item.frontmatter.title}
      </Link>
      <span
        className={
          visible && childrenCount > 0
            ? "dropdown-arrow --active"
            : childrenCount > 0
            ? "dropdown-arrow"
            : "dropdown-arrow --hidden"
        }
        onClick={() => {
          toggleVisible(!visible)
        }}
      >
        <img src={arrow} alt="Dropdown Arrow" aria-hidden="true" />
      </span>
    </span>
  )
}

export default PageLink
