import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { globalHistory } from "@reach/router"

import "./nav.scss"
import NavItem from "./nav-item"

import logo from "../images/logo.svg"

const Nav = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___order] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              path
              title
              sections {
                name
                anchor
              }
            }
          }
        }
      }
    }
  `)

  const parents = data.allMarkdownRemark.edges
  const currentLocation = globalHistory.location.pathname
    .replace(/\/$/, "")
    .split("/")

  return (
    <nav className="nav-main">
      <label for="nav-mobile" className="nav-mobile-btn">
        Menu
      </label>
      <Link to="/" className="logo-link">
        <img src={logo} alt="SIMC Logo" className="logo" />
      </Link>
      <input id="nav-mobile" type="checkbox" />
      <ul className="primary">
        {parents.map(parent => (
          <NavItem
            isActive={
              parent.node.frontmatter.path ===
              `/${currentLocation[currentLocation.length - 1]}`
            }
            key={parent.node.id}
            item={parent.node}
          />
        ))}
      </ul>
    </nav>
  )
}

export default Nav
