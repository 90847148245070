import React from "react"
import { Link } from "gatsby"

const Dropdown = ({ sections, item, visible }) => (
  <ul className={visible ? `secondary --active` : `secondary`}>
    {sections.map(section => (
      <li key={`${item.frontmatter.path}-${section.anchor}`}>
        <Link
          to={`${item.frontmatter.path}#${section.anchor}`}
          activeClassName="active"
        >
          {section.name}
        </Link>
      </li>
    ))}
  </ul>
)

export default Dropdown
