/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import "./layout.scss"

const Layout = ({ children }) => (
  <div className="container">
    <Nav />
    <main>{children}</main>
    <footer>
      © {new Date().getFullYear()} - Built by{" "}
      <a href="mailto:hello@sulphur.com.sg">Sulphur</a> for{" "}
      <a href="http://simc.com.sg">SIMC</a>
    </footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
